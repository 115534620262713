import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Deload Week`}</em></strong></p>
    <p>{`Mobilty work: Lower body`}</p>
    <p>{`Technique work: Pistols`}</p>
    <p>{`then, 20:00 amrap:`}</p>
    <p>{`200m Run`}</p>
    <p><em parentName="p">{`(100m’s = 10 reps, no less than 100m’s is scored)`}</em></p>
    <p>{`20 Reverse Lunges (10/leg, alternating)`}</p>
    <p>{`40 Double Unders`}</p>
    <p>{`40 Situps`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      